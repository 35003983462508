import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import TagManager from 'react-gtm-module-custom-domain';
import Toaster from 'components/commercetools-ui/toaster';
import { FrontasticProvider } from 'frontastic';
import 'tailwindcss/tailwind.css';
import 'styles/app.css';
import 'styles/themes/default.css';
import 'styles/themes/theme1.css';
import 'styles/themes/theme2.css';
import 'styles/themes/theme3.css';
import 'styles/components/content-slider.css';
import 'styles/components/slider-v1.css';
import 'styles/components/slider-v2.css';
import 'styles/components/slider-product.css';
import 'styles/components/default-loader.css';
import 'styles/components/tooltip.css';
import 'styles/components/offcanvas.css';
import 'styles/components/sticky-button.css';
import { CookiebotManager } from '../components/revelo-ui/cookiebot/CookiebotManager';
import { COOKIEBOT_CBID } from '../helpers/constants/cookiebot';
import { GTM_AUTH, GTM_CUSTOM_URL, GTM_DATA_LAYER, GTM_ID, GTM_PREVIEW } from '../helpers/constants/googleTagManager';
import useI18n from '../helpers/hooks/useI18n';
import nextI18NextConfig from '../next-i18next.config';
import localFont from 'next/font/local';
import { PageProps } from '@Types/page/PageFolder';
import { generateCanonicalUrl } from '../helpers/canonicalUrl';

const calmetta = localFont({
  src: [
    {
      path: '../public/fonts/Calmetta_W_Rg.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/Calmetta_W_Bd.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-calmetta',
});

const calmettaHeadline = localFont({
  src: [
    {
      path: '../public/fonts/Calmetta_W_XBd.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  variable: '--font-calmetta-headline',
});

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const { t: translate } = useI18n();

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: GTM_AUTH ?? '',
      preview: GTM_PREVIEW ?? '',
      dataLayerName: GTM_DATA_LAYER ?? 'dataLayer',
      customURL: GTM_CUSTOM_URL ?? '',
    });

    CookiebotManager.initialize({
      cbid: COOKIEBOT_CBID ?? '',
      dataLayerName: GTM_DATA_LAYER ?? 'dataLayer',
    });
  }, []);

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [keywords, setKeywords] = useState<string>();
  const [robotsTag, setRobotsTag] = useState<string>();
  const [canonicalUrl, setCanonicalUrl] = useState<string>();
  const router = useRouter();

  useEffect(() => {
    if ((pageProps as PageProps)?.data?.pageFolder?.configuration) {
      const { seoDescription, seoKeywords, seoTitle, robotsTag } = (pageProps as PageProps).data.pageFolder
        .configuration;

      if (seoTitle) setTitle(translate(seoTitle));
      if (seoDescription) setDescription(translate(seoDescription));
      if (seoKeywords) setKeywords(translate(seoKeywords));
      if (robotsTag) setRobotsTag(translate(robotsTag));
      setCanonicalUrl(generateCanonicalUrl(router, pageProps));
    }
  }, [pageProps, translate]);

  return (
    <main className={`${calmetta.variable} ${calmettaHeadline.variable} font-sans`}>
      <FrontasticProvider>
        <Head>
          {title && <title>{title}</title>}
          {description && <meta name="description" content={description} />}
          {keywords && <meta name="keywords" content={keywords} />}
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          {robotsTag ? (
            <meta name="robots" content={robotsTag} />
          ) : (pageProps as PageProps)?.data?.page?.shouldIndex ? (
            <meta name="robots" content="index, follow" />
          ) : (
            <meta name="robots" content="noindex, follow" />
          )}
          <style jsx global>{`
            html {
              font-family: ${calmetta.variable};
            }
          `}</style>
        </Head>
        <Component {...pageProps} />
        <Toaster />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden">`,
          }}
        />
      </FrontasticProvider>
    </main>
  );
}

export default appWithTranslation(FrontasticStarter, nextI18NextConfig);
